<template>
  <div>
    <div v-if="loading">
      <LoadingMatch />
    </div>
    <div class="body" v-else>
      <div class="scoreline">
        <div class="btn-wrap" @click="goBack()">
          <BackArrow />
        </div>

        <span>{{ formatDate(fixture.date) }}</span>
        <span>
          {{ fixture.country }} -
          {{ fixture.tournament }}
        </span>

        <div class="team-details">
          <div class="home">
            <div class="team">
              <p>{{ fixture.home_team }}</p>
            </div>
          </div>
          <div class="score">
            <span>{{ fixture?.home_score }}</span>
            <span>vs</span>
            <span>{{ fixture?.away_score }}</span>
          </div>
          <div class="away">
            <div class="team">
              <p>{{ fixture.away_team }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="select-rules">
        <p
          :class="{ active: activeTab === 'matchOdds' }"
          @click="setActive('matchOdds')"
        >
          {{ $t("matchOdds") }}
        </p>
        <p
          :class="{ active: activeTab === 'animation' }"
          @click="setActive('animation')"
        >
          {{ $t("liveTracker") }}
        </p>
        <p
          :class="{ active: activeTab === 'statistics' }"
          @click="setActive('statistics')"
        >
          {{ $t("statistics") }}
        </p>
      </div>

      <div v-if="activeTab === 'matchOdds'">
        <div class="catsec-home select-sport">
          <span
            v-for="sport in sportsList"
            :key="sport.id"
            :class="['cattit-home', { active: selectedSport.id === sport.id }]"
            :style="{
              border: selectedSport === sport.id ? '2px solid #a31212' : '',
            }"
            @click="setSportMenu(sport.id, sport.name)"
          >
            <img
              :style="{
                display: sport.icon ? '' : 'none',
              }"
              :src="sport.icon"
              class="menu-icon-sports-svg"
            />
            <span :v-if="sport.name"> {{ sport.name }} </span>
          </span>

          <span
            v-for="sport in marketFilters"
            :key="sport.id"
            :class="['cattit-home', { active: selectedSport.id === sport.id }]"
            :style="{
              border: selectedSport === sport.id ? '2px solid #a31212' : '',
            }"
            @click="setSportMenu(sport.id, sport.name)"
          >
            <img
              :style="{
                display: sport.icon ? '' : 'none',
              }"
              :src="sport.icon"
              class="menu-icon-sports-svg"
            />
            <span :v-if="sport.name"> {{ sport.name }} </span>
          </span>
        </div>

        <div v-if="loadingMarket"><LoadingMatch :gamesLoad="true" /></div>
        <div v-else>
          <Outcome
            v-for="(m, index) in visibleMarkets"
            :key="getKey(index)"
            :market_id="m.market_id"
            :market_name="m.market_name"
            :specifier="m.specifier"
            :status_name="m.status_name"
            :status="m.status"
            :fixture="fixture"
            :producer_status="fixture.producer_status"
            :producer_id="fixture.producer_id"
            :outcome="m.outcomes"
          />
        </div>

        <div class="px-2">
          <section class="promo-card-wrapper mb-3">
            <div
              style="background-color: var(--background-color)"
              class="text-dark p-2"
              data-toggle="collapse"
              data-target="#1"
              aria-expanded="false"
              aria-controls="1"
            >
              <strong>
                {{ fixture.home_team }} - {{ fixture.away_team }} Online
                Betting: Odds, Forecasts, Online Broadcast
              </strong>
              <span style="float: right"
                ><img style="width: 15px" src="/arrow-down.png"
              /></span>
            </div>
            <div
              style="background-color: var(--background-color)"
              class="promo-content text-dark collapse p-2"
              id="1"
            >
              <p>
                {{ fixture.date }} in the {{ fixture.tournament }} will be a
                match between {{ fixture.home_team }} and
                {{ fixture.away_team }} teams. Here you can see the betting odds
                for the event, and the latest performance of both teams.
              </p>
              <h6>
                Historical Performance {{ fixture.home_team }} -
                {{ fixture.away_team }}
              </h6>
              <p>
                On the event page you can find detailed stats of the teams and
                players, also the {{ fixture.tournament }} standings available.
                The infographic tab represents detailed data on each of the
                teams. Analytical data will help you make a sure bet on
                {{ fixture.home_team }} or {{ fixture.away_team }} based on
                their advantages and disadvantages and current rankings.
              </p>
              <h6>
                Betting Odds for {{ fixture.home_team }} -
                {{ fixture.away_team }}
              </h6>
              <p>
                Chopbet has the best odds for you to bet on the
                {{ fixture.home_team }} - {{ fixture.away_team }}. You can bet
                on: match winner, corners, yellow cards, goal scorer, and many
                other results of the match. You can learn odds and make bets in
                pre-match mode a few days before the match starts or in LIVE
                mode when the match starts.
              </p>
              <h6>
                {{ fixture.home_team }} - {{ fixture.away_team }}. Betting Tips
                and Predictions
              </h6>
              <p>
                Chopbet analysts set predictions on the outcome of events.
                Betting odds are a result of analytical predictions. The lower
                the odds, the higher the probability of a positive outcome. But
                it does not guarantee a 100% pass of the outcome, you should
                always evaluate and predict the outcome of the match on your
                own.
              </p>
              <h6>
                Watch {{ fixture.home_team }} - {{ fixture.away_team }} Live
                Streaming on Chopbet
              </h6>
              <p>
                Online broadcast of the {{ fixture.home_team }} vs.
                {{ fixture.away_team }} match is available only for registered
                users. It allows you to watch the match online and make bets in
                LIVE mode with an extended betting line. It will also be
                possible to follow the {{ fixture.home_team }} -
                {{ fixture.away_team }} match statistics to stay updated with
                betting odds changes.
              </p>
            </div>
          </section>
          <section class="promo-card-wrapper mb-3">
            <div
              style="background-color: var(--background-color)"
              class="text-dark p-2"
              data-toggle="collapse"
              data-target="#2"
              aria-expanded="false"
              aria-controls="2"
            >
              <strong>
                ☝️Frequently Asked Questions About {{ fixture.home_team }} vs
                {{ fixture.away_team }} Betting
                <span style="float: right"
                  ><img style="width: 15px" src="/arrow-down.png"
                /></span>
              </strong>
            </div>
            <div
              style="background-color: var(--background-color)"
              class="promo-content text-dark collapse p-2"
              id="2"
            >
              <h6>
                ❓ What are odds for {{ fixture.home_team }} vs
                {{ fixture.away_team }}?
              </h6>
              <p>
                ⚽ Odds for {{ fixture.home_team }} vs
                {{ fixture.away_team }} represent the likelihood of a specific
                event occurring during the England
                {{ fixture.tournament }} match between
                {{ fixture.home_team }} and {{ fixture.away_team }}. They also
                indicate the potential payout if your bet is successful.
              </p>
              <h6>
                ❓ What is a betting market in {{ fixture.home_team }} vs
                {{ fixture.away_team }}?
              </h6>
              <p>
                ⚽ A betting market in {{ fixture.home_team }} vs
                {{ fixture.away_team }} refers to a specific aspect of the
                {{ fixture.tournament }} match that you can place bets on. It
                could include options like the match-winner, total goals scored,
                halftime score, player goal scorers, and more.
              </p>
              <h6>
                ❓ Can I place live bets during the {{ fixture.home_team }} vs
                {{ fixture.away_team }} match?
              </h6>
              <p>
                ⚽ Yes, Chopbet offers live or in-play betting for
                {{ fixture.home_team }} vs {{ fixture.away_team }}
                match. This allows you to place bets while the game is ongoing,
                taking advantage of changing circumstances and dynamics.
              </p>
              <h6>
                ❓ Can I bet on my mobile device for the
                {{ fixture.home_team }} vs {{ fixture.away_team }} match?
              </h6>
              <p>
                ⚽ Absolutely, Chopbet offers mobile app that allow you to place
                bets on {{ fixture.tournament }} matches such as
                {{ fixture.home_team }} vs {{ fixture.away_team }}. This makes
                it convenient to bet on the go using your smartphone or tablet.
              </p>
            </div>
          </section>
        </div>
      </div>
      <div v-if="activeTab === 'animation'">
        <div class="animation">
          <iframe
            :src="`https://s5dev.sir.sportradar.com/choplifegamingmts/${lang}/1/season/118693/headtohead/2600/2527/match/${fixture.match_id}`"
            frameborder="0"
          ></iframe>
        </div>
      </div>
      <div v-if="activeTab === 'statistics'">
        <div class="animation">
          <!-- <p>Statistics cooming soon</p> -->
          <iframe
            src="https://s5dev.sir.sportradar.com/choplifegamingmts/"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Outcome from "../../components/outcome/Outcome.vue";
import fixtures from "@/services/fixtures";
import BackArrow from "../../components/icons/BackArrow.vue";

import mqtt from "mqtt";
import LoadingMatch from "./LoadingMatch.vue";
import { formatDate } from "../../utils/formatter";

(function (a, b, c, d, e, f, g, h, i) {
  a[e] ||
    ((i = a[e] =
      function () {
        (a[e].q = a[e].q || []).push(arguments);
      }),
    (i.l = 1 * new Date()),
    (i.o = f),
    (g = b.createElement(c)),
    (h = b.getElementsByTagName(c)[0]),
    (g.async = 1),
    (g.src = d),
    g.setAttribute("n", e),
    h.parentNode.insertBefore(g, h));
})(
  window,
  document,
  "script",
  "https://widgets.sir.sportradar.com/c579505099810db0db4c76b99aa21921/widgetloader",
  "SIR",
  {
    theme: false,
    language: "en",
  }
);

export default {
  name: "Match",
  components: {
    Outcome,
    BackArrow,
    LoadingMatch,
  },
  data: function () {
    return {
      selectedSport: {
        id: "all",
        name: "All",
      },
      sportsList: [
        // {
        //   id: 2,
        //   name: "Favourite",
        //   icon: "/menu/star1.svg",
        // },
        {
          id: "all",
          name: "All",
        },
      ],
      activeTab: "matchOdds",
      iconSize: 24,
      match_id: 0,
      game_id: 0,
      lang: this.$i18n.locale,
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      live: false,
      seconds: 0,
      mqttClient: false,
      iframeUrl: "",
      loading: false,
      marketFilters: [],
      loadingMarket: false,
      currenctGroup: [],
      visibleMarkets: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      // handler(to) {
      // react to route changes...
      // document.title = to.params.name.replace(/[^a-z0-9+]+/gi, " ");
      // document.description =
      //   "The best boosted odds for " +
      //   to.params.name.replace(/[^a-z0-9+]+/gi, " ") +
      //   " get free bonus, freebets and many cashback offers ";
      // },
    },
  },
  mounted: function () {
    // this.autoRefreshUI(this.$vnode.tag);
    this.$store.dispatch("setCurrentPage", "match");
    // this.game_id = to.params.game_id;
    this.game_id = this.$route.params.game_id;
    // this.live = to.params.status === "live";
    this.getMatch();
    this.subScribeToLive();
    // subscribe to UI event to update odds incase of odds change, this is done through event bus
    // we only expect to receive odds updates on this topic
  },
  computed: {
    marketGroups: function () {
      return this.$store.state.market_groups;
    },
    fixture: function () {
      //return this.fixture.market;
      return this.$store.state.fixture;
    },
    totals_18: function () {
      //return this.fixture.market;
      return this.$store.state.fixture.totals_18;
    },
    totals_238: function () {
      //return this.fixture.market;
      return this.$store.state.fixture.totals_238;
    },
    totals_166: function () {
      //return this.fixture.market;
      return this.$store.state.fixture.totals_166;
    },
    collapsibleMarkets: function () {
      var max = 9;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.$store.state.fixture.market, function (k, v) {
        x++;

        if (x > max) {
          markets.push(v);
        }
      });

      return [];
    },
    home_team: function () {
      return this.getHomeCompetitorName(this.$store.state.fixture.name);
    },
    away_team: function () {
      return this.getAwayCompetitorName(this.$store.state.fixture.name);
    },
    event_time: function () {
      var minutes = parseInt(this.$store.state.fixture.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if (minutes < 10) {
        min = "0" + min;
      }

      return min + "'";
    },
  },
  methods: {
    formatDate,
    subScribeToLive: function () {
      let matchStatus = "+";
      let vm = this;
      const newTopic = `feeds/${matchStatus}/odds_change/${this.fixture.match_id}`;
      this.$store.dispatch("subscribeToTopic", newTopic);
      const mqttClient = this.$store.state.mqttClient;
      if (mqttClient) {
        mqttClient.on("message", function (topic, msg) {
          var payload = JSON.parse(msg.toString());
          vm.uxUpdate(payload);
        });
      }
    },
    goBack() {
      if (this.disable) {
        return;
      }
      if (this.closeBet) {
        this.closeBetSlip();
        return;
      }
      if (this.goto) {
        this.$router.push(this.goto);
      } else {
        this.$router.go(-1);
      }
    },
    setActive(tab) {
      this.activeTab = tab;
    },
    setSportMenu(id, name) {
      this.selectedSport = {
        id: id,
        name: name,
      };

      const allMarket = this.$store.state.fixture.market;

      if (id === "all") {
        this.visibleMarkets = allMarket;
        return;
      }
      this.fetchMarketGroup(id);
    },
    initMqtt: function () {
      var vm = this;

      let matchStatus = "+"; // should be either prematch or live or + (all)

      let topics = [
        `feeds/${matchStatus}/odds_change/${this.fixture.match_id}`,
        "feeds/producer_status", // subscribe to all producer status updates
      ];

      if (this.mqttClient !== false) {
        this.mqttClient.end();
      }

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      };

      var client = mqtt.connect(process.env.VUE_APP_URL_MQTT_HOST, options);

      client.on("connect", function () {
        // subscribe to home page topics to update matches
        vm.jQuery.each(topics, function (k, v) {
          client.subscribe(v, function (err) {
            if (!err) {
              // console.log("subscribed to | " + v);
            }
          });
        });
      });
      client.on("message", function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      });

      this.mqttClient = client;
    },

    listenForOddsChange: function () {
      var vm = this;

      vm.EventBus.$on(
        "match:reload:" + this.fixture.match_id,
        function (updates) {
          vm.updateFixture(updates);
        }
      );
    },

    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "outcomes-id-" + index + "-");
    },

    getColumnWidth: function (a) {
      if (a.length === 2) {
        return "col-6";
      }

      if (a.length === 3) {
        return "col-4";
      }

      return "col-auto";
    },

    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },

    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },

    getMatch: async function () {
      var path = "/game/{game_id}";
      path = path.replace("{game_id}", this.game_id);
      this.loading = true;
      try {
        const res = await fixtures.get(path);
        var fixture = res.data;
        var initial_fixture = res.data;
        var path2 = `/markets/groups/sport/${res.data.sport_id}`;
        const filtersRes = await fixtures.get(path2);
        this.marketFilters = filtersRes.data;
        this.visibleMarkets = initial_fixture.market;
        // console.log(
        //   initial_fixture.market,
        //   "------ initial_fixture filtersRes ------"
        // );
        if (parseInt(initial_fixture.producer_id) !== 3) {
          this.live = true;
        } else {
          this.live = false;
        }
        // Format over/under
        var formatted_18 = {};
        var formatted_238 = {};
        var formatted_166 = {};
        var totals_18 = formatted_18;
        var totals_238 = formatted_238;
        var totals_166 = formatted_166;
        fixture.totals_18 = totals_18;
        fixture.totals_238 = totals_238;
        fixture.totals_166 = totals_166;
        if (fixture.event_time.length > 0) {
          var p = fixture.event_time.split(":");
          var minutes = p[0];
          var seconds = p[1];
          seconds = parseInt(seconds);
          seconds = seconds + parseInt(minutes) * 60;
          fixture.seconds = seconds;
        }
        this.$store.dispatch("setFixture", fixture);
        this.iframeUrl =
          "https://widget.chopbet.ci?match_id=" + this.fixture.match_id;
        this.initMqtt();
        this.listenForOddsChange();
        this.autoRefreshUI(this.$vnode.tag);
        setTimeout(() => {
          this.autoRefreshUI(this.$vnode.tag);
        }, 1000);
        setTimeout(() => {
          this.autoRefreshUI(this.$vnode.tag);
        }, 2000);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    updateFixture: function (updates) {
      //console.log(JSON.stringify(updates))

      var vm = this;
      var fixture = updates;

      vm.live = parseInt(fixture.producer_id) === 1;

      var formatted_18 = {};
      var formatted_238 = {};
      var formatted_166 = {};

      var totals_18 = formatted_18;
      var totals_238 = formatted_238;
      var totals_166 = formatted_166;

      fixture.totals_18 = totals_18;
      fixture.totals_238 = totals_238;
      fixture.totals_166 = totals_166;

      vm.$store.dispatch("setFixture", fixture);
      vm.autoRefreshUI(vm.$vnode.tag);
    },

    getOddDirectionClass: function (outcome) {
      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) != 1) {
        return "";
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {
        return "arrow green up";
      }

      return "arrow red down";
    },

    fetchMarketGroup: async function (market_group_id) {
      const allMarket = this.$store.state.fixture.market;
      if (this.selectedSport.id === "all") {
        this.visibleMarkets = allMarket;
        return;
      }
      this.loadingMarket = true;
      var path = `/markets/by-market-group/${market_group_id}`;
      const res = await fixtures.get(path);
      this.loadingMarket = false;
      const currenctGroup = [];
      res.data.forEach((market) => {
        currenctGroup.push(market.market_id);
      });
      this.currenctGroup = currenctGroup;
      this.filterMarkets(currenctGroup);
    },
    filterMarkets: function (currenctGroup) {
      const allMarket = this.$store.state.fixture.market;

      if (this.selectedSport.id === "all") {
        this.visibleMarkets = allMarket;
        return;
      }
      const filteredMarkets = allMarket.filter((market) =>
        currenctGroup.includes(market.market_id)
      );
      this.visibleMarkets = filteredMarkets;
    },
  },
  beforeDestroy: function () {
    // console.log("beforeDestroy");

    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
  destroyed: function () {
    // console.log("destroyed");
    if (this.mqttClient !== false) {
      this.mqttClient.end();
      this.mqttClient = false;
    }
  },
};
</script>
<style src="./index.css" scoped></style>
